import React from 'react';

const Footer = () => {
  return (
    <footer className="py-8 bg-black text-white text-center">
      <h2 className="pt-9 pb-8">PRIVATE APPOINTMENTS NOW AVAILABLE</h2>
      <div className='flex-center gap-2 pb-9'>
        <button className="btn-gray">
          BOOK NOW
        </button>
        <button className="btn-gray">
          DIRECTIONS
        </button>
      </div>
      <a href="tel:1300 308 295" className='text-p-xs pb-8'>T 1300 308 295</a>
      <div className='flex-center pb-8 pt-4 gap-4'>
        <img src="/icons/instagram.png" />
        <img src="/icons/facebook.png" />
      </div>
      <p className='terms pb-9'>Terms and Conditions / Privacy Policy</p>
      <div className="banner_slide flex-center">
        <p className='text-p'>
          Construction Underway
        </p>
      </div>
      <div className='flex-center pt-9 px-10 md:px-4 pb-8 flex-col md:flex-row gap-8'>
        <div className='flex-center gap-8 order-2 md:order-1'>
          <img src="/icons/riverlee.png" className='m-auto' />
          <img src="/icons/colliers.png" className='m-auto' />
        </div>

        <img src="/icons/hotel-white.png" className='m-auto pl-0 md:pl-10 order-1 md:order-2' />

        <div className='flex-center gap-5 md:gap-8 order-3 md:order-3 px-10 md:px-0'>
          <img src="/icons/xfk.png" className='m-auto' />
          <img src="/icons/carr.png" className='m-auto' />
          <img src="/icons/oculus.png" className='m-auto' />
          <img src="/icons/odc.png" className='m-auto' />
          <img src="/icons/icon.png" className='m-auto' />
        </div>
      </div>

    </footer>
  );
};

export default Footer;
