import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ toggleSidebar, isHeaderColorActive }) => {
  return (
    <header className={`flex justify-between items-center py-4 px-10 ${isHeaderColorActive ? '' : 'bg-black'} text-white fixed w-full z-10`}>
      <button
        onClick={toggleSidebar}
        aria-label="Toggle Menu"
      >
        <img src="/icons/nav.png" className='p-0 md:pl-3' />
      </button>

      <Link to="/"><img src="/images/logo.png" style={{ height: '45px' }} className='p-0 md:pl-10' /></Link>
      <button className="text-p-m bg-gray-100 hover:bg-black text-black hover:text-white border hover:border-white font-bold py-2 px-6 shadow-md hidden md:block">
        ENQUIRE NOW
      </button>
      <div className='block md:hidden'>Register</div>
    </header>
  );
};

export default Header;
