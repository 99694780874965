import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
        onClick={toggleSidebar}
      ></div>

      <div
        className={`fixed top-0 left-0 w-sidebar h-full bg-white shadow-lg transform transition-transform duration-300 sidebar ${isOpen ? 'translate-x-0 z-10' : '-translate-x-full'
          }`}
      >
        <div className="flex">
          <button onClick={toggleSidebar} aria-label="Close Menu">
            {/* Close Icon */}
            <svg className="w-8 h-8 text-gray-700" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round"
                strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <nav className="space-y-4 mt-9">
          <Link to="/" className="nav-list" onClick={toggleSidebar}>Residences</Link>
          <Link to="/penthouse" className="nav-list" onClick={toggleSidebar}>Penthouse</Link>

          <a href="#" className="nav-list" onClick={toggleSidebar}>Sheraton Resort</a>
          <a href="#" className="nav-list" onClick={toggleSidebar}>Sustanablity</a>
          <a href="#" className="nav-list" onClick={toggleSidebar}>Precinct</a>
          <a href="#" className="nav-list" onClick={toggleSidebar}>Amenity</a>
          <a href="#" className="nav-list" onClick={toggleSidebar}>News</a>
          -
          <a href="#" className="nav-list" onClick={toggleSidebar}>Register</a>

        </nav>
      </div>
    </>
  );
};

export default Sidebar;
