import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header, Sidebar, Home, Penthouse, Residence } from './';

function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      '/': 'Esplanade Hervey Bay',
      '/penthouse': 'Penthouse',
    };

    document.title = `Azurean | ${pageTitles[location.pathname] || 'Unknown Page'}`;
  }, [location]);
}

function Layout({ toggleSidebar, isSidebarOpen, isHeaderColorActive }) {
  usePageTitle(); // Hook for setting the page title

  return (
    <>
      <Header toggleSidebar={toggleSidebar} isHeaderColorActive={isHeaderColorActive} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/penthouse" element={<Penthouse />} />
        <Route path="/residence" element={<Residence />} />
      </Routes>
    </>
  );
}

export default Layout;
