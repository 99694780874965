import React, { useEffect } from 'react';
import '../App.css';

const Penthouse = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');
    const banners = document.querySelectorAll('.animate-slide-in-left');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    banners.forEach(banner => observer.observe(banner));
    sections.forEach(section => observer.observe(section));


    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      banners.forEach(banner => observer.unobserve(banner));
    };
  }, []);

  return (
    <div className="font-sans">
      <main>
        <section id="hero" className="bg-gray-100">
          <div >
            <video autoPlay={true} muted={true} controls={false} style={{ height: '100%', width: '100%' }}>
              <source src="https://production.metaluxe.com.au/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBczhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--261063affff950910b3a1f0f3e2fe6314c41c7d5/Sheraton%20Master%20Plan.mp4" type="video/mp4" />
            </video>
          </div>
        </section>

        <div id='banner'>
          <div className='animate-slide-in-left'>
            <div className="banner_slide flex-center">
              <p className='text-p'>
                Construction Underway
              </p>
            </div>
          </div>
        </div>

        <section className='my-9'>
          <div className='fade-in-section mx-8'>
            <p className="text-p">
              2-BEDROOM RESIDENCES FROM $1,390,000<br />
              3-BEDROOM RESIDENCES FROM $3,050,000<br />
              PENTHOUSE BY APPOINTMENT</p>
          </div>
        </section>
        <section className='my-9'>
          <div className='fade-in-section'>
            <p className="text-p-s">
              Situated on the final piece of Melbourne’s original <br />
              waterfront, Seafarers welcomes to the city Australia’s<br />
              first 1 Hotel and Melbourne’s first international<br />
              hotel-branded residences.
            </p>
          </div>
        </section>
        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/101.jpeg" className='mt-8 w-auto m-auto' />
              <p className='text-p-m mt-2'>SEAFARERS EXTERIOR, NORTH WHARF
                <em className="artists-text">— Artist’s impression</em>
              </p>
            </div>
          </div>
        </section >

        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <img src="/icons/residences.png" className='m-auto' />
            <p className="text-p-s my-8">
              Comprising just 114 light-filled, immaculately crafted residences by Carr Design, <br />
              this landmark development heralds a new era of luxury riverfront living with the <br />
              five-star amenity and services of the world’s leading sustainable luxury hotel at <br />
              your door, and everything you love about Melbourne, just a river walk away.
            </p>
            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section mt-9 m-auto'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-6 px-8'>
                <div>
                  <img src="/images/102.jpeg" className='h-full' />
                  <p className='text-p-m mt-2'>ARRIVAL CONSERVATORY, PENTHOUSE —
                    <em className="artists-text">Artist’s impression</em>
                  </p>
                </div>

                <div>
                  <img src="/images/103.png" className='h-full' />
                  <p className='text-p-m mt-2'>COCKTAIL LOUNGE, PENTHOUSE —
                    <em className="artists-text"> Artist’s impression</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <img src="/icons/penthouse.png" className='m-auto' />

            <p className="text-p-s my-8">
              The Penthouse at Seafarers is a majestic, riverfront residence without precedent<br />
              or peer. Here, luxury is not a word but a promise—a commitment to those for<br />
              whom only the extraordinary is acceptable—of uncompromising design,<br />
              unrivalled convenience, and unsurpassed amenity. Melbourne’s first international-<br />
              hotel branded penthouse, its grandeur is expressed across 719 square metres of<br />
              meticulously planned, exquisitely resolved opulence. A magnificent, sun-<br />
              drenched sanctuary of expansive spaces and breathtaking Yarra River views.<br />
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section'>
            <img src="/images/104.png" className='mt-10 w-full' />
            <p className='text-p-m mt-2'>SEAFARERS EXTERIOR, NORTH WHARF
            </p>
          </div>
        </section >

        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <img src="/icons/hotel.png" className='m-auto' />
            <p className="text-p-s my-8">
              A Seafarers lifestyle extends far beyond the luxuries of your grand-scale home. <br />
              As Melbourne’s first international hotel-branded residences, it also places a<br />
              dedicated team of 200 staff at your service, and gives you access to all the guest<br />
              amenities and services of Australia’s first 1 Hotel.<br />
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section'>
            <img src="/images/104.png" className='mt-10 w-full' />
            <p className='text-p-m mt-2'>PORTE COCHERE —
              <em className="artists-text">Artist’s impression</em>
            </p>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section text-center mx-8'>
            <img src="/icons/architecture.png" className='m-auto' />

            <p className="text-lg text-center my-8">
              Centred on an inspired reimagining of the heritage-protected Goods Shed 5 by<br />
              Fender Katsalidis, the bold sculptural composition of Seafarers is a sublime<br />
              addition to the cityscape. Its regeneration is a chance to bring the unique island<br />
              setting back to life while celebrating the location’s rich maritime history.<br />
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/105.jpeg" className='mt-9 w-auto m-auto' />
              <p className='text-center'>SEAFARERS EXTERIOR, NORTH WHARF
              </p>
            </div>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>FRASER COAST</p>
                  </div>
                  <img src="/images/105.jpeg" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Seafarers will change the way you view the Yarra, in
                    every sense. More than a river...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>RESIDENCES</p>
                  </div>
                  <img src="/images/106.png" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Seafarers’ faceted exterior does more than create a
                    striking urban presence, it yields uniquely large..</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>SPA</p>
                  </div>
                  <img src="/images/107.png" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Construction on site is well underway, including the
                    restoration of the heritage-listed Goods Shed No5...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="py-8 bg-black text-white text-center">
          <h2 className="text-p pt-9 pb-8">PRIVATE APPOINTMENTS NOW AVAILABLE</h2>
          <div className='flex-center gap-2 pb-9'>
            <button className="btn-gray">
              BOOK NOW
            </button>
            <button className="btn-gray">
              DIRECTIONS
            </button>
          </div>
          <a href="tel:1300 308 295" className='text-p-xs pb-8'>T 1300 308 295</a>
          <div className='flex-center pb-8 pt-4 gap-4'>
            <img src="/icons/instagram.png" />
            <img src="/icons/facebook.png" />
          </div>
          <p className='terms pb-9'>Terms and Conditions / Privacy Policy</p>
          <div className="banner_slide flex-center">
            <p className='text-p'>
              Construction Underway
            </p>
          </div>
          <div className='flex-center pt-9 px-10 pb-8 flex-col md:flex-row gap-8'>
            <div className='flex-center gap-8 order-2 md:order-1'>
              <img src="/icons/riverlee.png" className='m-auto' />
              <img src="/icons/colliers.png" className='m-auto' />
            </div>

            <img src="/icons/hotel-white.png" className='m-auto pl-0 md:pl-10 order-1 md:order-2' />

            <div className='flex-center gap-5 md:gap-8 order-3 md:order-3 px-10 md:px-0'>
              <img src="/icons/xfk.png" className='m-auto' />
              <img src="/icons/carr.png" className='m-auto' />
              <img src="/icons/oculus.png" className='m-auto' />
              <img src="/icons/odc.png" className='m-auto' />
              <img src="/icons/icon.png" className='m-auto' />
            </div>
          </div>
        </footer>
      </main >
    </div >
  );
};

export default Penthouse;
