import React, { useEffect } from 'react';
import '../App.css';
import Footer from './Footer';

const Home = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');
    const banners = document.querySelectorAll('.animate-slide-in-left');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    banners.forEach(banner => observer.observe(banner));
    sections.forEach(section => observer.observe(section));


    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      banners.forEach(banner => observer.unobserve(banner));
    };
  }, []);

  return (
    <div className="font-sans">
      <main>
        <section id="hero" className="bg-gray-100">
          <div >
            <video autoPlay={true} muted={true} controls={false} style={{ height: '100%', width: '100%' }}>
              <source src="https://production.metaluxe.com.au/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBczhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--261063affff950910b3a1f0f3e2fe6314c41c7d5/Sheraton%20Master%20Plan.mp4" type="video/mp4" />
            </video>
          </div>
        </section>

        <div id='banner'>
          <div className='animate-slide-in-left'>
            <div className="banner_slide flex-center">
              <p className='text-p'>
                Construction Underway
              </p>
            </div>
          </div>
        </div>

        <section className='my-9'>
          <div className='fade-in-section mx-8'>
            <p className="text-p">
              2-BEDROOM RESIDENCES FROM $999,000<br />
              3-BEDROOM RESIDENCES FROM $1,100,000<br />
              PENTHOUSE BY APPOINTMENT</p>
          </div>
        </section>
        <section className='my-9'>
          <div className='fade-in-section'>
            <p className="text-p-s">
              Located on Hervey Bay’s iconic Esplanade, this development  <br />
              brings  the region's first international hotel-branded <br />
              residences and a luxury waterfront hotel, offering unparalleled <br />
              coastal living.

            </p>
          </div>
        </section>
        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/101.jpeg" className='mt-8 w-auto m-auto' />
              <p className='text-p-m mt-2'>SEAFARERS EXTERIOR, NORTH WHARF
                <em className="artists-text">— Artist’s impression</em>
              </p>
            </div>
          </div>
        </section >

        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <img src="/icons/residences.png" className='m-auto' />
            <p className="text-p-s my-8">
              Featuring hundreds of meticulously designed, light-filled residences by Mode Design, <br />
              this landmark development redefines luxury waterfront living. With five-star amenities<br />
              and sustainable luxury hotel services, all while being steps from Hervey Bay’s finest <br />
              offerings.
            </p>
            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section mt-9 m-auto'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-6 px-8'>
                <div>
                  <img src="/images/102.jpeg" className='h-full' />
                  <p className='text-p-m mt-2'>ARRIVAL CONSERVATORY, PENTHOUSE —
                    <em className="artists-text">Artist’s impression</em>
                  </p>
                </div>

                <div>
                  <img src="/images/103.png" className='h-full' />
                  <p className='text-p-m mt-2'>COCKTAIL LOUNGE, PENTHOUSE —
                    <em className="artists-text"> Artist’s impression</em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <img src="/icons/penthouse.png" className='m-auto' />

            <p className="text-p-s my-8">
              The Penthouse at Seafarers is a majestic, riverfront residence without precedent<br />
              or peer. Here, luxury is not a word but a promise—a commitment to those for<br />
              whom only the extraordinary is acceptable—of uncompromising design,<br />
              unrivalled convenience, and unsurpassed amenity. Melbourne’s first international-<br />
              hotel branded penthouse, its grandeur is expressed across 719 square metres of<br />
              meticulously planned, exquisitely resolved opulence. A magnificent, sun-<br />
              drenched sanctuary of expansive spaces and breathtaking Yarra River views.<br />
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section'>
            <img src="/images/104.png" className='mt-10 w-full' />
            <p className='text-p-m mt-2'>SEAFARERS EXTERIOR, NORTH WHARF
            </p>
          </div>
        </section >

        <section className='my-9'>
          <div className='fade-in-section text-center px-8'>
            <h4 className='text-3xl font-extrabold uppercase'>Sheraton Hervey Bay Hotel</h4>
            <p className="text-p-s my-8">
              This exclusive lifestyle goes beyond the luxury of your waterfront home, offering<br />
              access to world-class amenities and a dedicated team at your service. Enjoy the <br />
              benefits of Hervey Bay’s first international hotel-branded residences and five-star<br />
              hotel services.
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section'>
            <img src="/images/104.png" className='mt-10 w-full' />
            <p className='text-p-m mt-2'>PORTE COCHERE —
              <em className="artists-text">Artist’s impression</em>
            </p>
          </div>
        </section>

        <section className='my-9'>
          <div className='fade-in-section text-center mx-8'>
            <img src="/icons/architecture.png" className='m-auto' />

            <p className="text-lg text-center my-8">
              Centred on an inspired reimagining of the heritage-protected Goods Shed 5 by<br />
              Fender Katsalidis, the bold sculptural composition of Seafarers is a sublime<br />
              addition to the cityscape. Its regeneration is a chance to bring the unique island<br />
              setting back to life while celebrating the location’s rich maritime history.<br />
            </p>

            <button className="btn">
              DISCOVER MORE
            </button>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <img src="/images/105.jpeg" className='mt-9 w-auto m-auto' />
              <p className='text-center'>SEAFARERS EXTERIOR, NORTH WHARF
              </p>
            </div>
          </div>
        </section>

        <section className='my-9'>
          <div className='shell'>
            <div className='fade-in-section'>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>FRASER COAST</p>
                  </div>
                  <img src="/images/105.jpeg" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Seafarers will change the way you view the Yarra, in
                    every sense. More than a river...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>RESIDENCES</p>
                  </div>
                  <img src="/images/106.png" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Seafarers’ faceted exterior does more than create a
                    striking urban presence, it yields uniquely large..</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
                <div className='flex flex-col'>
                  <div className="border-top">
                    <p className='title pt-5'>SPA</p>
                  </div>
                  <img src="/images/107.png" className='my-5 h-full' />
                  <p className='text-p-xs pt-2 pb-3'>Construction on site is well underway, including the
                    restoration of the heritage-listed Goods Shed No5...</p>
                  <a href="#" className='text-p-xs underline'>DISCOVER MORE</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div >
  );
};

export default Home;
