import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Home, Penthouse, Header, Sidebar, Layout } from './components';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHeaderColorActive, setIsHeaderColorActive] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderColorActive(false);
      } else {
        setIsHeaderColorActive(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <Layout
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        isHeaderColorActive={isHeaderColorActive}
      />
    </Router>
  );
}

export default App;
